// Footer.tsx

import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const LinkSection = ({
	title,
	links,
}) => {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return (
		<div className='md:col-span-1 lg:col-span-1'>
			<h3 className='text-lg font-semibold mb-4'>{title}</h3>
			<div className='flex flex-col space-y-2'>
				{links.map((link) =>
					link.newTab ? (
						<a
							href={link.href}
							target='_blank'
							rel='noopener noreferrer'
							className='hover:text-gray-300 transition duration-300'>
							{link.text}
						</a>
					) : (
						<Link to={link.href} className='hover:text-gray-300 transition duration-300'>
							{link.text}
						</Link>
					)
				)}
			</div>
		</div>
	)
}

const Footer = () => {
	const navigationLinks = [
		{ href: '/', text: 'Início' },
		{ href: '/amicitia/chorus', text: 'Amicitia Chorus' },
		{ href: '/amicitia/crescendo', text: 'Amicitia Crescendo' },
		{ href: '/amicitia/string-quartet', text: 'Amicitia String Quartet' },
	]

	const socialMediaLinks = [
		{ href: 'https://www.facebook.com/amicitiachorus', text: 'Facebook', newTab: true },
		{ href: 'https://www.instagram.com/amicitiachorus/', text: 'Instagram', newTab: true },
		{ href: 'https://www.youtube.com/@AmicitiaChorus', text: 'Youtube', newTab: true },
	]

	const contactInfo = [
		{ href: 'mailto:associacaoamiciart@gmail.com', text: 'associacaoamiciart@gmail.com', newTab: true },
		{ href: '', text: '918102399', newTab: false },
	]

	return (
		<footer className='bg-primary-100 text-white p-12 justify-center md:w-screen relative'>
			<div className='md:mx-auto md:w-3/4 lg:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
				<LinkSection title='Navegação' links={navigationLinks} />

				<LinkSection title='Redes Sociais' links={socialMediaLinks} />

				<LinkSection title='Contacto' links={contactInfo} />

				{/* Logo and Slogan */}
				<div className='md:col-span-2 lg:col-span-3 flex flex-col md:flex-row items-start'>
					<img src='/images/graphics/logos/amiciart.png' alt='Amiciart Logo' className='w-24 h-auto mb-4 md:mr-4' />
					<div>
						<h3 className='text-lg font-semibold mb-2'>Amiciart</h3>
						<p className='text-sm'>Juntos pela amizade e pela música.</p>
					</div>
				</div>

				{/* Copyright Notice */}
				<div className='col-span-1 md:col-span-2 lg:col-span-4'>
					<p className='text-sm'>&copy; 2024 Amiciart. Todos os direitos reservados.</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer