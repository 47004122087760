import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageShadow from 'react-image-shadow';
import 'react-image-shadow/assets/index.css';
import 'tailwindcss/tailwind.css';
import ArrowDown from '../components/ArrowDown.jsx';
import Concert from '../components/Concert.jsx';
import Page from '../components/Page.jsx';
import Subtitle from '../components/Subtitle.jsx';
import '../styles/globals.css';
import HeroCarousel from './amicitia/HeroCarousel.jsx';

const scrollDown = () => {
  const element = document.getElementById('sobre-nos');
  if (element !== null) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const Amicitia = () => {
  const [shadowBlur, setShadowBlur] = useState(20);
  const [repertoireBgColor, setRepertoireBgColor] = useState('black');

  useEffect(() => {
    let isBlurringOut = true;
    let minBlurChange = 0;
    let maxBlurChange = 10;

    const interval = setInterval(() => {
      setShadowBlur((prevBlur) => {
        let newBlur = prevBlur;

        if (isBlurringOut) {
          newBlur -=
            Math.random() * (maxBlurChange - minBlurChange) + minBlurChange;
          if (newBlur <= 0) {
            isBlurringOut = false;
            minBlurChange = 1;
            maxBlurChange = 5;
          }
        } else {
          newBlur +=
            Math.random() * (maxBlurChange - minBlurChange) + minBlurChange;
          if (newBlur >= 500) {
            isBlurringOut = true;
            minBlurChange = 0;
            maxBlurChange = 10;
          }
        }

        return newBlur;
      });
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <Page
      title='Amicitia Chorus'
      hero={
        <>
          <HeroCarousel />
          <ArrowDown onClick={scrollDown} text="saber mais" color="white" />
        </>
      }
      logo="/images/graphics/logos/tia-green.svg"
    >
      <Helmet>
        {/* preload */}
        <link
          rel="preload"
          as="image"
          href="/images/photos/misericordia.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/photos/programs/gregoriano.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/photos/programs/tradicao.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/photos/programs/abril.webp"
        />
        <link rel="preload" as="image" href="/images/photos/programs/ff.webp" />
        <link
          rel="preload"
          as="image"
          href="/images/photos/programs/tematico.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/1.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/2.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/3.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/4.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/5.webp"
        />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/carousel/6.webp"
        />
      </Helmet>
      <section className="text-black">
        <div className="px-3 py-20 h-screen flex items-center flex-col justify-center bg-gradient-to-br from-white to-secondary-900 md:w-screen md:h-screen md:flex md:flex-row md:justify-center md:gap-10 md:px-0 xl:gap-20">
          <ImageShadow
            className="hidden object-cover lg:block rounded"
            src="/images/photos/misericordia.webp"
            shadowBlur={shadowBlur}
            width={600}
          />

          <div className="md:w-96">
            <Subtitle
              borderColor="black"
              text="Sobre Nós"
              id="sobre-nos"
              level={1}
            />
            <span className="">
              Edificado pelo companheirismo de jovens que, movidos pela amizade
              e por paixão pela música, o Amicitia Chorus vê-se fundado em 2018.
              Estabelecendo-se enquanto um ensemble coral de referência com o
              objetivo assente de promover, difundir e dar novos contributos à
              música coral - quer num âmbito sacro quer profano - apresenta-se
              diligentemente nos mais diversos recintos e eventos numa versátil
              transversalidade musical, providenciando um distinto contributo
              para a qualificação e dinamização cultural da região.
            </span>
          </div>
        </div>

        <div
          className="flex flex-col px-3 pt-5 text-white"
          style={{
            backgroundColor: repertoireBgColor,
            transition: 'background-color 10s',
          }}
        >
          <div className="flex w-full lg:mx-auto md:justify-center md:gap-10 md:items-center">
            {/* <div className='hidden md:w-1/3 h-5 md:block' /> */}
            <Subtitle
              level={1}
              text="Repertório"
              className="w-full md:text-center md:w-96 text-3xl"
            />
          </div>

          <Concert
            image="/images/photos/programs/gregoriano.webp"
            title="Do Gregoriano ao Romântico"
            text="O concerto celebra a música coral desde os seus primórdios até à extinção das Ordens Religiosas em Portugal. Partindo do Canto Gregoriano, o concerto evolui para o auge da polifonia do Período Renascentista e anos mais tarde para o Período Barroco. Passando pelo Período Clássico, uma das poucas obras para coro misto a capella de W.A. Mozart é ainda interpretada. O concerto encerra com uma peça de Franz Schubert, destacando o PeríodoRomântico, aquando da extinção das Ordens Religiosas em Portugal."
            setRepertoireBgColor={setRepertoireBgColor}
          />

          <Concert
            image="/images/photos/programs/tradicao.webp"
            title="Sementes de Tradição"
            text="O concerto propõe uma reinterpretação moderna de temáticas populares regionais. Parte de uma iniciativa Amicitia em dinamizar o panorama da música coral numa inusitada coleção de influências das diversas regiões Lusitanas. Interpretando obras originais exclusivamente de compositores portugueses, o concerto visa o enriquecimento do espólio nacional e a expansão da música coral com novo repertório orgulhosamente assente nas ricas e profundas raízes Portuguesas."
            setRepertoireBgColor={setRepertoireBgColor}
          />

          <Concert
            image="/images/photos/programs/abril.webp"
            title="Povo que Cantas Abril"
            text="O concerto propõe-se como um reafirmar de apreço por algo que facilmente se vê dado como garantido: a Liberdade Social. Parte de uma iniciativa contínua Amicitia em estimular o panorama da música coral, com um intuito vincado de evidenciar fortes raízes Portuguesas num dinamizardo espólio cultural as composições de Cláudio Barruma vêem-se assentes na obra de Zeca e Godinho naquele que é um celebrar da mensagem de luta e esperança contra a opressão, hiperbolizado pelo poder emocional do âmbito coral erudito em contiguidade com quarteto de cordas."
            setRepertoireBgColor={setRepertoireBgColor}
          />
          <Concert
            image="/images/photos/programs/ff.webp"
            title="Fantasia A Capella"
            text="O concerto propõe-se a recriar grandes obras sonoras do mundo da fantasia, como “Game of Thrones” e “The Lord of The Rings” com recurso à música coral. Como fios narrativos, as vozes conduzem-nos através da história, mergulhando num contraste entre a tensão e a beleza melódica. Desde os andamentos sombrios que nos transportam das sombras até às batalhas, à leveza das melodias serenas que exploram a riqueza emocional das personagens. A interpretação do coro de arranjos originais de Cláudio Barruma promete capturar toda a diversidade presente na banda sonora convidando os seus ouvintes a uma viagem onde a harmonia se torna um portal para mundos além da própria imaginação"
            setRepertoireBgColor={setRepertoireBgColor}
          />
          <Concert
            image="/images/photos/programs/tematico.webp"
            title="Concerto Temático"
            text="O Amicitia Chorus apresenta também a disponibilidade para desenvolver um programa temático dedicado e pensado exclusivamente para um determinado espaço, evento ou comemoração. Este tipo de concerto visa colmatar eventuais vontades que necessitem de uma abordagem específica correspondendo assim às necessidades do público"
            setRepertoireBgColor={setRepertoireBgColor}
          />
        </div>
      </section>
    </Page>
  )
};

export default Amicitia;
