import React from 'react'
import 'react-image-shadow/assets/index.css'
import 'tailwindcss/tailwind.css'
import Page from '../components/Page.jsx'
import '../styles/globals.css'

const AmicitiaStringQuartet = () => {
  return (
    <Page
      title="Amicitia String Quartet"
      hero={
        <>
          <img
            className="object-cover w-full h-full"
            src="/images/graphics/strings.webp"
            alt="Big Group"
          />
        </>
      }
      logo="/images/graphics/logos/string-quartet.webp"
    >
      <p className="flex justify-center py-32">Mais informações em breve</p>
    </Page>
  )
}

export default AmicitiaStringQuartet
