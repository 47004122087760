import React from 'react'
import { Helmet } from 'react-helmet'
import 'react-image-shadow/assets/index.css'
import { Link } from 'react-router-dom'
import 'tailwindcss/tailwind.css'
import Footer from '../components/Footer.jsx'
import '../styles/globals.css'


const Page = ({ title, hero, children, logo }) => {
	return (
    <main className="">
			<Helmet>
				<title>{title}</title>
			</Helmet>
      <div className="hero min-h-screen relative z-10">{hero}</div>

      <div className="hidden md:block md:fixed bottom-5 left-5 p-3 z-0">
        <img src={logo} alt="Group Logo" width={100} height={100} />
      </div>

      <Link
        to="/"
        className="fixed flex text-white top-5 left-5 w-32 bg-primary-100 z-50 flex-row items-center"
      >
        <img
          src="/images/graphics/logos/amiciart.png"
          alt="Amiciart Logo"
          className="mr-2"
          style={{ width: '30px', height: '30px' }}
        />
        AMICIART
      </Link>

      {children}

      <Footer />
    </main>
  )
}

export default Page